
import { Vue, Component, Prop } from "vue-property-decorator";
import { SecurityGroupAccessType } from "@/pages/manage/security-group/common/consts";
import { ListDataSource } from "@/data/List/ListDataSource";

@Component
export default class SecurityGroupDialog extends Vue {
  @Prop() value!: boolean;
  @Prop({ default: () => [] }) exludedUserProfileIdx!: number[];
  @Prop({ default: () => [] }) exludedRoleIdx!: number[];
  securityGroupAccessType: SecurityGroupAccessType =
    SecurityGroupAccessType.UserProfile;
  resolve!: Function;

  securityGroupAccessTypes = [
    {
      caption: "Пользователь",
      value: SecurityGroupAccessType.UserProfile,
    },
    {
      caption: "Роли",
      value: SecurityGroupAccessType.Role,
    },
  ];

  userProfileFilterPredicate(up: any) {
    return !this.exludedUserProfileIdx.some((i) => i == up.id);
  }

  roleFilterPredicate(r: any) {
    return !this.exludedRoleIdx.some((i) => i == r.id);
  }

  userProfileDataSource: ListDataSource = new ListDataSource({
    className: "userProfile",
    config: {
      pageIndex: 1,
      pageSize: 15,
    },
  });

  roleDataSource: ListDataSource = new ListDataSource({
    config: {
      pageIndex: 1,
      pageSize: 15,
    },
    className: "roles",
  });

  roleMetaData: any = {
    properties: [
      {
        name: "id",
        type: "string",
        description: "Уникальный идентификатор компонента.",
        caption: "ID",
        editor: "readonly",
        priority: 1,
        group: "general",
        visibleInTable: true,
        validations: [],
        config: {},
      },
      {
        name: "name",
        type: "string",
        description:
          'Строковое уникальное имя компонента, которое используется как ЧПУ при адресации. Уникальность обеспечивается в рамках одного класса компонентов. Например компоненты класса "Статья" и "Страница" могут иметь одинаковый ЧПУ, но двух статей с одинаковыми ЧПУ быть не должно. ',
        caption: "Системное имя",
        editor: "systemname",
        priority: 100,
        group: "general",
        visibleInTable: true,
        validations: [
          {
            validator: "max",
            value: 150,
            errorMessage: "Поле не может содержать больше чем 150 символов",
          },
          {
            validator: "required",
            value: true,
            errorMessage: "Поле не может ,быть пустым",
          },
        ],
        config: {
          bindedFieldName: "normalizedName",
        },
      },
      {
        name: "normalizedName",
        type: "string",
        description:
          "Заголовок компонента. Обычно используется как основной заголовок при визуализации как страницы, так и виджетов.",
        caption: "Подпись",
        editor: "string",
        priority: 100,
        group: "general",
        visibleInTable: false,
        validations: [
          {
            validator: "max",
            value: 250,
            errorMessage: "Поле не может содержать больше чем 250 символов",
          },
          {
            validator: "required",
            value: true,
            errorMessage: "Поле не может быть пустым",
          },
        ],
        config: {
          bindedFieldName: "name",
        },
      },
    ],
  };

  addByUserProfile(userProfile: any) {
    this.$emit("add-by-user-profile", {
      accessType: SecurityGroupAccessType.UserProfile,
      userProfileId: userProfile.id,
    });
  }

  addByRole(item) {
    this.$emit("add-by-role", {
      accessType: SecurityGroupAccessType.Role,
      roleId: item.id,
    });
  }
}
