
import { Vue, Component, Ref } from "vue-property-decorator";
import {
  ListDataSource,
  SecurityGroupAccessListDataSource,
  SecurityGroupUserProfileListDataSource
} from "@/data/List/ListDataSource";
import DataSecurityList from "@/components/common/data/security-group-list.vue";
import { mdiAccount, mdiShieldAccountOutline } from "@mdi/js";
import SecurityGroupDialog from "@/pages/manage/security-group/components/security-group-dialog.vue";
import {
  SecurityGroupAccessType,
  SecurityGroupDialogTarget,
} from "./common/consts";
import { Filter } from "@/cms-services/filter";

enum ListDialogTarget
{
  Access,
  Group
}
@Component({
  components: { SecurityGroupDialog },
})
export default class Home extends Vue {
  @Ref("securityList") securityList!: DataSecurityList;
  accessDialogVisible: boolean = false;
  listDialogVisible:boolean = false
  listDialogTarget:number= ListDialogTarget.Group;
  emails:string = "";
  securityGroupDialogTarget: SecurityGroupDialogTarget =
    SecurityGroupDialogTarget.UserProfile;
  icons: any = {
    role: mdiShieldAccountOutline,
    userProfile: mdiAccount,
  };

  dataSource: SecurityGroupUserProfileListDataSource = new SecurityGroupUserProfileListDataSource({
    pageIndex: 1,
    pageSize: 100,
  });

  accessDataSource: SecurityGroupAccessListDataSource = new SecurityGroupAccessListDataSource({
    pageIndex: 1,
    pageSize: 100,
  });

  async addUserList(){
    if (!this.securityList.currentFolderId) {
      this.$message("Пожалуйста выберите безопасность", "error");
      return;
    }
    const emailAddresses = this.emails.split('\n');
    const securityGroupId = this.securityList.currentFolderId;
    switch (this.listDialogTarget){
      case ListDialogTarget.Group:
      {
        await this.dataSource.addUserListByEmails({emailAddresses, securityGroupId })
        await this.dataSource.get();
        break;
      }

      case ListDialogTarget.Access:
      {
        await this.accessDataSource.addUserListByEmails({emailAddresses, securityGroupId })
        await this.accessDataSource.get();
        break;
      }
    }

    this.$message("Данные успешно добавлены")
    this.listDialogVisible=false;
    this.emails = "";
  }

  get userProfilesExcludedIdx() {
    return this.securityGroupDialogTarget ==
      SecurityGroupDialogTarget.UserProfile
      ? this.dataSource.items
          .filter((i) => i.accessType == SecurityGroupAccessType.UserProfile)
          .map((i) => i.userProfileId)
      : this.accessDataSource.items
          .filter((i) => i.accessType == SecurityGroupAccessType.UserProfile)
          .map((i) => i.userProfileId);
  }
  get rolesExcludedIdx() {
    return this.securityGroupDialogTarget ==
      SecurityGroupDialogTarget.UserProfile
      ? this.dataSource.items
          .filter((i) => i.accessType == SecurityGroupAccessType.Role)
          .map((i) => i.roleId)
      : this.accessDataSource.items
          .filter((i) => i.accessType == SecurityGroupAccessType.Role)
          .map((i) => i.roleId);
  }

  dialog: boolean = false;

  async openAccessDialog() {
    if (!this.securityList.currentFolderId) {
      this.$message("Пожалуйста выберите безопасность", "error");
      return;
    }
    this.accessDataSource.config.filter = JSON.stringify([
      new Filter("securityGroupId", +this.securityList.currentFolderId),
    ]);

    await this.accessDataSource.get();
    this.accessDialogVisible = true;
  }

  folderConfig: any = {
    className: "securityGroup",
    rootFolder: "mainFolder",
  };
  $message: any;

  getCaption(item: any) {
    return item.userProfile?.fullName ?? item.role?.name;
  }

  get breadcrumbs() {
    return [
      {
        text: "Главная",
        to: "/",
      },
      {
        text: "Группы безопасности",
        disabled: true,
      },
    ];
  }

  headers: any[] = [
    {
      text: "ID",
      value: "id",
      sortable: true,
    },
    {
      text: "Тип доступа",
      value: "type",
      sortable: false,
      align: "center",
    },
    {
      text: "Подпись",
      value: "caption",
      sortable: true,
    },
    {
      text: "Статус",
      value: "instanceStateId",
      sortable: false,
    },
  ];

  openDialog(target: SecurityGroupDialogTarget) {
    if (!this.securityList.currentFolderId) {
      this.$message("Пожалуйста выберите безопасность", "error");
      return;
    }
    this.securityGroupDialogTarget = target;

    this.dialog = true;
  }

  async addByUserProfile(data: any) {
    if (!this.securityList.currentFolderId) {
      this.$message("Пожалуйста выберите безопасность", "error");
      return;
    }
    switch (this.securityGroupDialogTarget) {
      case SecurityGroupDialogTarget.UserProfile:
        await this.dataSource.add({
          ...data,
          securityGroupId: this.securityList.currentFolderId,
        });
        this.dataSource.total = this.dataSource.items.length;
        break;

      case SecurityGroupDialogTarget.Access:
        await this.accessDataSource.add({
          ...data,
          securityGroupId: this.securityList.currentFolderId,
        });
        this.accessDataSource.total = this.accessDataSource.items.length;
        break;
    }

    this.$message("Позльзователь успешно добавлено в группу");
  }

  async addByRole(data: any) {
    if (!this.securityList.currentFolderId) {
      this.$message("Пожалуйста выберите безопасность", "error");
      return;
    }

    switch (this.securityGroupDialogTarget) {
      case SecurityGroupDialogTarget.UserProfile:
        await this.dataSource.add({
          ...data,
          securityGroupId: this.securityList.currentFolderId,
        });
        this.dataSource.total = this.dataSource.items.length;
        break;

      case SecurityGroupDialogTarget.Access:
        await this.accessDataSource.add({
          ...data,
          securityGroupId: this.securityList.currentFolderId,
        });
        this.accessDataSource.total = this.accessDataSource.items.length;
        break;
    }
    this.dataSource.total = this.dataSource.items.length;
    this.$message("Позльзователь успешно добавлено в группу");
  }

  async created() {
    await Promise.all([this.accessDataSource.get(), this.dataSource.get()]);
  }
}
